import * as React from 'react';
import { Input, Button, Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

export interface IProps extends FormComponentProps {
    onSuccess: (code: string) => void;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Code extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue, getFieldValue} = this.props.form;
        const codeError = getFieldError('code');

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Item
                    validateStatus={codeError ? 'error' : 'success'}
                    help={codeError || ''}
                    label="Code"
                >
                    {getFieldDecorator('code', {
                        rules: [{required: true, message: 'Bitte Visualisierungs-Code eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    code: e.target.value
                                })
                            }}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        Visualisierung abrufen
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSuccess(values.code);
            }
        });
    }
}

export default Form.create()(Code);