import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import { getApiHost, getApiPort, getApiProtocol } from '../util/env';
import ISetVisual from "../action/ISetVisual";

export default function getVisual(code: string): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/public/visual/detail/${code}`, {
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json'
                },
                method: 'GET',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            }).then((result) => {
                const setVisual: ISetVisual = {
                    type: types.set_visual,
                    visual: result
                };

                dispatch(setVisual);
                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                resolve();
            }).catch((response: Response) => {
                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}