import * as React from 'react';

class Footer extends React.Component<{}, {}> {
    public render() {
        return (
            <div className="footer-container">
                <footer className="wrapper"></footer>
            </div>
        );
    }
}

export default Footer;