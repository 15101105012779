import { assign } from 'lodash';
import IState from '../state/IState';
import { actions, types } from '../action/constants';

export default function reduce(state: IState, action: actions): IState {
    switch (action.type) {
        case types.set_busy:
            return assign({}, state, {
                busy: action.busy
            });
        case types.set_visual:
            return assign({}, state, {
                visual: action.visual
            });
        default:
            return state;
    }
};