import * as React from 'react';

const logo = require('../../img/logo.png');

class Header extends React.Component<{}, {}> {
    constructor(props: {}, context: {}) {
        super(props, context);
    }

    public render() {
        let headerClasses = ['header-container'];

        return (
            <div id="cHeaderElement" className={headerClasses.join(' ')}>
                <header className="wrapper clearfix">
                    <div id="cLogo"><img src={logo}/></div>
                </header>
            </div>
        );
    }
}

export default Header;