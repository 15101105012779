import * as React from 'react';
import { assign } from 'lodash';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import { applyMiddleware, createStore, Store as ReduxStore } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import indexReduce from '../reducer/reduce';
import IState from '../state/IState';
import { actions } from '../action/constants';
import Blocker from './Blocker';
import Container from './Container';

export default class Index extends React.Component<{}, {}> {
    protected reduxStore: ReduxStore<{}>;

    constructor(props: {}, context: {}) {
        super(props, context);

        let middlewares: any = [thunk];

        if (process.env.NODE_ENV === 'development') {
            middlewares.push(createLogger({collapsed: true, diff: false}));
        }

        this.reduxStore = createStore<any>(
            (state: IState, action: actions): IState => {
                const reducers = [indexReduce];

                for (let i = 0; i < reducers.length; i++) {
                    state = assign({}, state, reducers[i](state, action));
                }

                return state;
            },
            {},
            applyMiddleware(...middlewares)
        );
    }

    public render() {
        const Routes = () => (
            <div>
                <Route exact path="/" component={
                    () => <Redirect to="/visuals"/>
                }/>
                <Route path="/visuals" exact={true} component={Container}/>
                <Route path="/visuals/:code" component={Container}/>
            </div>
        );

        return (
            <ReduxProvider store={this.reduxStore}>
                <div>
                    <Blocker/>
                    <Router>
                        <Routes/>
                    </Router>
                </div>
            </ReduxProvider>
        );
    }
}