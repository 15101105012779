import { assign } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IVisual from '../model/IVisual';

export default function setVisual(visual: IVisual): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            dispatch({
                type: types.set_visual,
                visual: assign({}, visual)
            });

            resolve();
        });
    }
}