import ISetVisual from './ISetVisual';
import ISetBusy from './ISetBusy';
import IShowCodeForm from "./IShowCodeForm";

export type actions =
    ISetBusy
    | ISetVisual
    | IShowCodeForm;

export enum types {
    set_busy = 'set_busy',
    set_visual = 'set_visual',
    show_code_form = 'show_code_form'
}