import { ThunkAction } from 'redux-thunk';
import { types } from '../action/constants';
import IShowCodeForm from '../action/IShowCodeForm';

export default function showCodeForm(show: boolean): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const showCodeForm: IShowCodeForm = {
                type: types.show_code_form,
                show
            };

            dispatch(showCodeForm);

            resolve();
        });
    }
}