import * as React from 'react';
import Header from './layout/Header';
import Content from './layout/Content';
import Footer from './layout/Footer';
import Code from './Code';
import { Modal, notification, Button, Icon, Row, Col, Alert } from 'antd';
import { get, forEach, filter, indexOf } from 'lodash';
import { connect } from 'react-redux';
import IVisual from '../model/IVisual';
import { RouteComponentProps } from 'react-router';
import Slider from 'react-slick';
import {getApiHost, getApiPort, getApiProtocol} from "../util/env";
import showCodeForm from '../creator/showCodeForm';
import setVisual from '../creator/setVisual';
import getVisual from '../creator/getVisual';

import '../../node_modules/slick-carousel/slick/slick.less';
import '../../node_modules/slick-carousel/slick/slick-theme.less';

export interface IProps extends RouteComponentProps<{ code?: string }> {
    showCodeForm?: (show: boolean) => void;
    visual?: IVisual;
    getVisual?: (code: string) => void;
    setVisual?: (visual: IVisual) => void;
    code?: string;
}

class Index extends React.Component<IProps, {}> {
    protected slider: Slider;

    componentWillMount() {
        if(this.props.match.params.code) {
            this.props.getVisual(this.props.match.params.code);
        }
    }

    public render() {
        return (
            <div>
                <Header />
                <Content>
                    {this.props.visual && (
                        <Row gutter={32}>
                            <Col xs={32} sm={24} key="summary-visualization">
                                {this.renderVisual()}
                            </Col>
                        </Row>
                    )}
                    {!this.props.visual && !this.props.match.params.code && (
                        this.renderVisualModal()
                    )}
                </Content>
                <Footer/>
            </div>
        );
    }

    protected renderVisualModal() {
        return (
            <Modal
                title="Visualisierungscode eingeben"
                closable={true}
                visible={true}
                destroyOnClose={true}
                footer={null}
                onCancel={() => {
                    this.props.showCodeForm(false);
                }}
            >
                <Code
                    onSuccess={(code: string) => {
                        this.props.showCodeForm(false);
                        this.props.getVisual(code);
                    }}
                />
            </Modal>
        );
    }

    protected renderVisual() {
        const images = this.props.visual.images;
        let groups: string[] = [];
        let renderImages: any[] = [];

        forEach(images, (file) => {
            if(indexOf(groups, file.group) === -1) {
                groups.push(file.group);
            }
        });
        groups.sort();

        forEach(groups, (group) => {
            let currentGroupImages = filter(images, { 'group': group });
            if(group == '1') {
                let nextGroup = filter(images, { 'group': '2' });
                if(nextGroup.length !== 0) {
                    return;
                }
            }
            forEach(currentGroupImages, (visual) => {
                renderImages.push(
                    <div style={{position: 'relative'}} key={`visual-${visual.id}`} className="summary-image">
                        <div style={{width: '100%', height: '100%', position: 'absolute', zIndex: 10000}}>&nbsp;</div>
                        <img
                            src={`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/uploads/${visual.file}`}/>
                    </div>
                );
            });
        });

        return (
            <div>
                <div>
                    <h1>
                        <span>{this.props.visual.firstName1} {this.props.visual.lastName1}</span>
                        {this.props.visual.firstName2 &&
                            <span> und {this.props.visual.firstName2} {this.props.visual.lastName2}</span>
                        }
                    </h1>
                    <h2>
                        Hier findet Ihr Eure persönlichen Visualisierungen
                    </h2>
                </div>
                <div style={{
                    paddingBottom: '20px'
                }}>
                    <Slider fade={true} dots={true} arrows={false} ref={(slider) => {
                        if (slider) {
                            this.slider = slider;
                        }
                    }}>
                        {renderImages}
                    </Slider>
                    {images.length > 0 && (
                        <Button style={{float: 'left'}} onClick={() => {
                            if (this.slider) {
                                this.slider.slickPrev();
                            }
                        }}>
                            <Icon type="arrow-left"/>
                        </Button>
                    )}
                    {images.length > 0 && (
                        <Button style={{float: 'right'}} onClick={() => {
                            if (this.slider) {
                                this.slider.slickNext();
                            }
                        }}>
                            <Icon type="arrow-right"/>
                        </Button>
                    )}
                </div>
                <br /><br />
                <Alert
                    description={
                        <div>
                            Die bereitgestellten Visualisierungen und Zeichnungen unterliegen dem deutschen Urheberrecht.
                            Jede Art der Vervielfältigung und Weitergabe an Dritte bedarf einer schriftlichen, vorherigen
                            und ausdrücklichen Genehmigung der TAFF-Haus GmbH. Das unerlaubte Kopieren/Speichern der
                            bereitgestellten Visualisierungen und Zeichnungen ist nicht gestattet und strafbar.

                            Informationen zum Datenschutz finden Sie hier: <a href="https://www.taff-haus.de/datenschutz">Datenschutz</a> |
                            <a href="https://www.taff-haus.de/impressum"> Impressum</a>
                        </div>
                    }
                    message="Datenschutz- und Urheberrechtshinweis"
                    type="info"
                    showIcon
                />
            </div>
        );
    }
}

export default connect(
    (state: any): Partial<IProps> => {
        return {
            isCodeFormVisible: get(state, 'showCodeForm', false),
            visual: get(state, 'visual', null),
        } as Partial<IProps>
    },
    (dispatch) => ({
        getVisual: (code) => {
            dispatch(getVisual(code)).catch(() => {
                dispatch(showCodeForm(false));

                notification['error']({
                    message: 'Fehler',
                    description: 'Die Visualisierungen konnten nicht geladen werden.'
                });
            });
        },
        showCodeForm: (show: boolean) => {
            dispatch(showCodeForm(show));
        },
        setVisual: (visual: IVisual) => {
            dispatch(setVisual(visual));
        },
    } as Partial<IProps>)
)(Index) as any;